@import "~include-media/dist/include-media";
@import "../../styles/common";
@import "../../styles/colors";

.ctaCircle {
  text-align: center;

  @include media(">=md") {
    text-align: left;
    width: rem(legacy-rem(550))
  }

  @include media(">=lg") {
    max-width: rem(legacy-rem(550));
  }

  h2 {
    font-size: rem(legacy-rem(24));
    line-height: rem(legacy-rem(25));
    margin-bottom: rem(legacy-rem(10));
    font-family: $headerFont;
    color: $primary;
    width: 100%;

    @include media ("<=sm") {
      font-size: rem(18);
      line-height: rem(25);
      margin:0 auto;
      text-align: center;
      color: $white;
    }

    @include media(">=md") {
      font-size: rem(legacy-rem(26));
      line-height: rem(legacy-rem(35));
      margin-bottom: rem(legacy-rem(30));
      color: #071727;
    }
  }

  .content {
    backface-visibility: hidden;
    color: $primary;
    font-size: rem(legacy-rem(14));
    line-height: rem(legacy-rem(20));
    margin-bottom: rem(legacy-rem(40));
    font-family: $bodyFont;
    width: 100%;
    word-break: normal;

    @include media ('>=sm') {
      max-width: rem(400);
    }

    @include media (">=md") {
      font-size: rem(legacy-rem(18));
      line-height: rem(legacy-rem(30));
      color: #4A4A4A;
    }

    @include media("<=sm") {
      display: none;
    }
  }

  .btnWrapper {
    backface-visibility: hidden;
    width: 100%;
    display: flex;
    justify-content: center;

    @include media ("<=sm") {
      background-color: $white;
      margin:rem(legacy-rem(25)) auto;
      width: rem(legacy-rem(115));
      height: rem(legacy-rem(115));
      white-space: normal;
      mask-image: url('../../../public/images/masks/fcp_image_circle_mask.svg');
      -webkit-mask-image: url('../../../public/images/masks/fcp_image_circle_mask.svg');
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      justify-content: center;
    }

    @include media (">=md") {
      justify-content: flex-start;
    }

    a {
      color: $primary;
      font-size: rem(legacy-rem(17));
      white-space: normal;
        @include media("<=sm") {
          display: block;
          color: $black;
          text-decoration: underline;
          margin-top: rem(legacy-rem(32));
          margin-left: rem(legacy-rem(10));
          width: rem(legacy-rem(100));
          transform: rotate(-15deg);
          .arrow {
            position: relative;
            display: block;
            fill: $brand-pink;
            margin: auto;
            stroke-width:1.0001;
            stroke-miterlimit:4.0005;
            width: 32%;
            left: rem(5);
            top: rem(6);
            stroke: $brand-pink;
            transform: rotateZ(9deg);
            padding: 0;
          }
          .link_link__underline-svg {
            color: $white;
          }

          svg {
            display: none;
          }
        }
        @include media(">sm") {
        .arrow {
          display: none;
        }
      }

      @include media (">=md") {
        font-size: rem(legacy-rem(20));
        color: #0F1822;
      }
    }
  }

  .contentBody {
    backface-visibility: hidden;
    @include media (">=sm") {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      max-width: rem(legacy-rem(600));
      min-height: rem(legacy-rem(600));
      background-color: $white;
      mask-image: url('../../../public/images/masks/fcp_image_circle_mask.svg');
      -webkit-mask-image: url('../../../public/images/masks/fcp_image_circle_mask.svg');
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }


    @include media (">=xl") {
      max-width: rem(legacy-rem(590));
      min-height: rem(legacy-rem(590));
    }

    .innerWrapper {
        h2 {
          @include media ("<lg") {
            margin-bottom: rem(30);
          }
          @include media ("<md") {
            margin-bottom: rem(25);
          }
        }

      @include media ("<md") {
        margin-top: rem(legacy-rem(-150));
        max-width: rem(legacy-rem(400));
        margin-left: rem(legacy-rem(30));
        margin-right: rem(legacy-rem(30));
      }
      @include media (">=md") {
        margin-left: rem(legacy-rem(65));
        margin-top: rem(legacy-rem(-35));
        width: 70%;
      }
      @include media ("<=sm") {
        margin: 0 auto;
      }
    }
  }
}

.linkText {
  margin: unset;

  @include media ("<sm") {
    white-space: normal;
    text-decoration: underline;
    font-size: rem(legacy-rem(13));
    line-height: rem(legacy-rem(16));
  }
}
