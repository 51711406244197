@import "~include-media/dist/include-media";
@import "../../styles/common";

.twoColumn {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  color: #000;
  margin: 0 auto;
  font-family: $headerFont;
  font-weight: 100;

  @include media(">=md") {
    width: 85%;
    padding-bottom: rem(legacy-rem(50));
  }

  @include media(">=xl") {
    width: 80%;
    padding-bottom: 0;
  }

  .leftContent {
    flex-direction: column;
    column-gap: rem(legacy-rem(50));

    @include media(">=lg") {
      flex-direction: row;
    }
  }

  .leftColumnBody {
    text-align: left;

    h2 {
      font-size: rem(legacy-rem(28));
      line-height: rem(legacy-rem(40));
      font-family: $headerFont;
      text-align: left;
      margin-bottom: rem(legacy-rem(30));

      @include media(">=md") {
        text-align: center;
      }

      @include media(">=lg") {
        font-size: rem(legacy-rem(60));
        line-height: rem(legacy-rem(70));
        text-align: left;
        margin-bottom: unset;
      }


      @include media(">=xl") {
        font-size: rem(legacy-rem(65));
      }
    }

    span {
      color: #FF6274;
      font-size: rem(legacy-rem(35));

      @include media(">=lg") {
        font-size: rem(legacy-rem(60));
      }
    }
  }

  .rightColumnBody {
    font-size: rem(15);
    font-family: $bodyFont;
    font-weight: $medium;
    text-align: left;
    line-height: rem(22);
    font-family: $bodyFont;
    margin-bottom: rem(legacy-rem(40));

    @include media(">=lg") {
      font-size: rem(22);
      line-height: rem(35);
      margin-top: rem(legacy-rem(25));
      max-width: rem(legacy-rem(698));
      margin-bottom: rem(legacy-rem(55));
    }
  }
  .rightActionButton {
    display: flex;
    justify-content: flex-start;
    margin-bottom: rem(50);

    @include media(">=md") {
      justify-content: center;
    }

    @include media(">=lg") {
      justify-content: flex-start;
      margin-bottom: unset;
    }
    & > a {
      font-family: $bodyFont;
      font-weight: $bold;
      font-size: rem(22);
      line-height: rem(40);
      border-radius: rem(legacy-rem(10));
      font-size: rem(17);
      padding: rem(legacy-rem(7)) rem(legacy-rem(30));

      @include media(">=lg") {
        font-size: rem(22);
        padding: rem(legacy-rem(15)) rem(legacy-rem(40));
      }
      svg {
        transform: scale(1.1);
        margin-top: rem(legacy-rem(5));
        margin-left: rem(legacy-rem(15));
      }
    }

  }

  @each $breakpoint, $width in $container-max-width {
    @include media(">=#{$breakpoint}") {
      max-width: rem($width);
    }
  }
}
.darkMode {
  color: #fff;

  h2 {
    color: #fff;
  }

  p {
    color: #fff;
  }
}


.rightContent {
  justify-content: flex-start;

  @include media(">=md") {
    justify-content: center;
  }

  @include media(">=lg") {
    justify-content: flex-start;
  }
}

.question > svg {
  width: rem(20);
  height: rem(20);
  margin-left: rem(-3);

  @include media(">=lg") {
    width: rem(45);
    height: rem(45);
    margin-left: rem(-9);
  }
}

.exclamation > svg {
  width: rem(20);
  height: rem(20);
  margin-left: rem(-5);

  @include media(">=lg") {
    width: rem(45);
    height: rem(45);
    margin-left: rem(-15);
  }
}