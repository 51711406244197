@use "sass:map";

@import "~include-media/dist/include-media";
@import '../../styles/common';

.footerContainer {
  width: 100%;

  @include media("<xl") {
    background-color: $primary;
  }

  @include media(">=lg") {
    height: rem(600);
  }

  @include media(">=xl") {
    height: rem(900);
    margin-top: rem(60);
  }
}

.parraFooter {
  justify-content: space-between;

  @include media(">=xl") {
    padding: 0;
  }
}

.fixedFooter {
  @include media(">=xl") {
    padding: 0;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: rem(20);
  }
}

.footerTopCurve {
  width: 100%;
  height: rem(75);
  background: url('../../../public/images/masks/mobile_footer_svg_top.svg') no-repeat top;
  display: block;
  background-size: cover;

  @include media(">=md") {
    height: rem(100);
  }

  @include media(">=lg") {
    height: rem(160);
  }

  @include media(">=xl") {
    display: none;
  }
}

.footer {
  backface-visibility: hidden;
  overflow-y: hidden;
  padding-top: rem(75);
  margin-left: rem(legacy-rem(-1));
  z-index: 0;

  @include media(">=md") {
    padding-top: rem(100);
  }

  @include media(">=xl") {
    background-color: $primary;
    -webkit-mask-image: url('../../../public/images/masks/footer_svg.svg');
    -webkit-mask-repeat: no-repeat;
    mask-image: url('../../../public/images/masks/footer_svg.svg');
    mask-repeat: no-repeat;
    mask-size: cover;
    -webkit-mask-size: cover;
    padding-top: rem(legacy-rem(200));
    height: rem(900);
  }

  &__hasCta {
    margin-top: rem(legacy-rem(-60));
    margin-left: rem(legacy-rem(-1));

    @include media(">=500px") {
      margin-top: rem(legacy-rem(-85));
    }

    @include media(">=md") {
      margin-top: rem(legacy-rem(-140));
    }

    @include media(">=lg") {
      margin-top: rem(legacy-rem(-220));
    }

    @include media(">=xl") {
      margin-top: rem(legacy-rem(-210));
    }
  }

  &__columns {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include media(">=xl") {
      display: flex;
      flex-direction: row;
    }

    @include media(">=md") {
      padding: rem(legacy-rem(60)) 0 0 0;
    }

    @include media(">=xl") {
      padding: rem(legacy-rem(60)) 0;
    }
  }

  @include media("<xl") {
    &__column-social {
      order: 2;
    }

    &__column-default {
      order: 1;
    }
  }

  &__column-default,
  &__column-social,
  &__column-contact {

    a, p {
      margin: 0;
      color: rgba($white, 0.8);
      line-height: rem(22);
      font-size: rem(14);

      @include media(">=xl") {
        line-height: rem(legacy-rem(30));
        font-size: rem(legacy-rem(18));
      }
    }

    a {
      color: rgba($white, 0.8);
      text-decoration: none;

      &:hover {
        color: $primary-lightblue;
        transition: color $link-transition-time;
      }
    }
  }


  &__column-contact {
    display: inline-block;

    &__logo {
      margin-bottom: rem(legacy-rem(30));

      @include media(">=xl") {
        margin-bottom: rem(legacy-rem(69));
      }

      svg {
        width: rem(111);
        height: auto;
        margin-bottom: 0;

        @include media(">=md") {
          width: rem(120);
        }

        @include media(">=xl") {
          width: rem(legacy-rem(170));
          margin-right: auto;
        }
      }
    }

    &__address {
      margin: 0 0 rem(legacy-rem(20)) 0;

      @include media(">=xs") {
        width: 40%;
      }

      @include media(">=lg") {
        width: unset;
        margin-bottom: rem(legacy-rem(30));
      }
    }

    &__mobrow {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include media(">=md") {
        flex-direction: row;
      }

      @include media(">=lg") {
        padding-bottom: rem(50);
        flex-direction: column;
      }
    }
  }


  &__column-social {
    margin-top: rem(legacy-rem(35));

    @include media(">=xl") {
      margin-top: rem(legacy-rem(90));
    }

    &__icons {
      color: rgba($white, 0.8);

      a {
        &:focus {
          outline: none;
        }
      }
      svg {
        width: auto;
        height: rem(legacy-rem(25));
        padding-right: rem(legacy-rem(50));
        cursor: pointer;

        @include media(">=xs") {
          padding-right: rem(legacy-rem(25));
        }

        @include media(">=xl") {
          height: rem(legacy-rem(30));
        }

        &:hover {
          color: $primary-lightblue;
          transition: color $link-transition-time;
        }

        &[class^="svg-inline"] {
          width: auto;
        }
      }
    }
  }


  &__column-default {
    @include media(">=lg") {
      width: rem(260);
    }

    @include media(">=xl") {
      width: rem(300);
    }

    &__accordion {
      width: 100%;
    }

    @include media(">=md") {
      display: inline-block;
      padding-top: rem(20);
    }

    &__heading {
      color: white;
      background-image:
      radial-gradient(circle, rgba(255, 255, 255, 0.5) rem(1.25), rgba(255,255,255,0) rem(2));
      background-position: bottom;
      background-size: rem(9) rem(5), rem(5) rem(9);
      background-repeat: repeat-x, repeat-y;
      margin: 0;
      font-size: rem(15);
      font-family: $bodyFont;

      @include media(">=lg") {
        font-size: rem(legacy-rem(18));
        padding-bottom: rem(legacy-rem(10));
      }

      @include media(">=xl") {
        font-size: rem(legacy-rem(20));
      }
    }

    &__content {
      margin-top: rem(10);
      margin-bottom: rem(10);
      p {
        a {
          line-height: rem(34);
          font-size: rem(14);
          color: rgb(255,255,255, .8);
        }
      }

      @include media(">=xl") {
        margin-top: rem(legacy-rem(30));
        margin-bottom: 0;

        p {
          line-height: rem(legacy-rem(40));

          a {
            line-height: rem(legacy-rem(40));
            font-size: rem(legacy-rem(18));
          }
        }
      }
    }
  }

  &__copyright {
    text-align: left;
    padding-bottom: rem(45);
    padding-top: rem(20);
    margin: auto;
    display: block;

    p:first-child {
      padding-right: rem(10);
    }

    @include media(">lg") {
      padding-top: 0;
      padding-bottom: rem(legacy-rem(150));
      display: flex;
    }

    p {
      letter-spacing: 0;
      color: $white;
      line-height: 100%;
      font-size: rem(12);
      margin-bottom: rem(25);
      width: unset;

      & > span {

        padding-right: rem(7);
      }

      & > span:nth-child(1) {
        padding-right: unset;
      }

      & > a {
        color: $white;
        padding-right: rem(7);
        text-decoration: none;
      }

      @include media(">=xl") {
        & > a:nth-child(1) {
          padding-left: rem(7);
        }
      }
      & > a:nth-child(2) {
        padding-left: rem(7);
      }
    }
  }
}

.topRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media(">=lg") {
    flex-direction: row;

    & > :nth-child(1) {
      margin-right: rem(200);
    }
  }
}

.copyrightContainer {
  justify-content: flex-start;
  margin-top: rem(20);
  color: rgba($white, 0.6) !important;

  & > div  p {
    color: rgba($white, 0.6);
    margin-bottom: rem(10);

    & > a {
      color: rgba($white, 0.6);
    }
  }

  @include media(">=xl") {
    margin-top: rem(94);
  }
}

.footerColumn {
  flex-direction: column;
  display: flex;
  width: 100%
}

.mobileIcons {
  margin-bottom: rem(40);

  svg {
    width: auto;
    height: rem(legacy-rem(25));
    cursor: pointer;
    color: rgba($white, 0.8);
    padding-right: rem(25);

    @include media(">=xl") {
      height: rem(legacy-rem(30));
    }

    &:hover {
      color: $primary-lightblue;
      transition: color $link-transition-time;
    }

    &[class^="svg-inline"] {
      width: auto;
    }
  }

  a:last-child > svg {
    @include media(">=xs") {
      padding-right: unset;
    }
  }
}

.privacyTerms {
  &:hover {
    opacity: 1;
    color: $primary-lightblue;
    transition: color opacity $link-transition-time;
  }
}

.featuredContainer {
  @include media(">=md") {
    margin-bottom: rem(200);
  }
}

.featuredPostWrapper {
  position: relative;
  &::after {
    top: -971px;
    position: absolute;
    content: "";
    background-color: #F4F4F4;
    height: 1500px;
    width: 100%;
    z-index: -1;
    -webkit-mask-size: cover;
    mask-size: cover;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: url('../../../public/images/footer/grey_merged_bg-01.svg');
    -webkit-mask-image: url('../../../public/images/footer/grey_merged_bg-01.svg');

    @include media(">=sm") {
      top: -900px;
      height: 2000px;
    }

    @include media(">=xxxxl") {
      height: 2700px;
      top: -1350px;
    }
  }

  &::before {
    top: 70%;
    position: absolute;
    content: "";
    background-color: #F4F4F4;
    height: 70%;
    width: 100%;
    z-index: -1;
  }
}
