@import "~include-media/dist/include-media";
@import '../../../../styles/common';

$spacer-breakpoints: (
  xs: 20px,
  sm: 40px,
  md: 80px,
  lg: 120px,
);

$spacer-breakpoints-medium: (
  xs: 10px,
  sm: 20px,
  md: 40px,
  lg: 60px,
);

$spacer-breakpoints-small: (
  xs: 5px,
  sm: 10px,
  md: 20px,
  lg: 30px,

);

.spacer {
  width: 100%;

  @include media(">=lg") {
    padding: 0 rem(legacy-rem(105));
  }
}

.spacerLarge {
  height: rem(60);

  @include media(">=xl") {
    height: rem(120);
  }
}

.spacerMedium {
  height: rem(30);

  @include media(">=xl") {
    height: rem(60);
  }
}

.spacerSmall {
  height: rem(15);

  @include media(">=xl") {
    height: rem(30);
  }
}

.hr {
  border: none;
  border-top: rem(1) solid #dadada;

  @include media(">=lg") {
    border-top: rem(6) solid #dadada;
  }
}

.hrLarge {
  margin-top: rem(30);
  margin-bottom: rem(30);

  @include media(">=xl") {
    margin-top: rem(60);
    margin-bottom: rem(60);
  }
}

.hrMedium {
  margin-top: rem(15);
  margin-bottom: rem(15);

  @include media(">=xl") {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }
}

.hrSmall {
  margin-top: rem(15);
  margin-bottom: rem(15);

  @include media(">=xl") {
    margin-top: rem(7.5);
    margin-bottom: rem(7.5);
  }
}
@include media("<md") {
  .hiddenMobile {
    height: 0 !important;
  }
}