@import "~include-media/dist/include-media";
@import '../../styles/variables';
@import '../../styles/colors';
@import "../../styles/functions";

.ctaWrapper {
    margin-bottom: rem(-50);

  .ctaInnerWrapper {
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-mask-image: url('../../../public/images/bottom_image_curve_mobile.svg');
    -webkit-mask-size: auto;
    -webkit-mask-repeat: no-repeat;
    mask-image: url('../../../public/images/bottom_image_curve_mobile.svg');
    mask-size: cover;
    mask-repeat: no-repeat;
    background-size: auto 100%;

    @include media("<=xs") {
      mask-position: bottom;
    }
  }

  .contentWrapper {
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(legacy-rem(80)) rem(legacy-rem(20)) rem(legacy-rem(150));
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-position-x .1s ease-in;

    @include media("<=xs") {
      padding: rem(100) rem(20) rem(150);
      background-position-x: 33%;
    }

    @include media(">=md") {
      padding: rem(legacy-rem(150)) 0;
    }

    @include media(">=lg") {
      padding: rem(legacy-rem(250)) 0;
    }

    @include media(">=xl") {
      min-height: rem(legacy-rem(900));
      padding-bottom: rem(legacy-rem(350));
    }

    &::before {
      position: absolute;
      content: '';
      background-color: rgba(31, 48, 63, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      @include media("<=lg") {
        background-color: rgba(15, 24, 34, 0.5);
      }
    }

    > * {
      z-index: 20;
      position: relative;
      @include media ("<=xs") {
        margin-left: rem(legacy-rem(15));
      }
    }
  }
}

.variant-circle {
  @include media(">=md") {
    .contentWrapper {

      &::before {
        display: none;
      }
    }
  }
}


@supports (background-attachment: fixed) {
  @include media(">=xxl") {
    .contentWrapper {
      background-attachment: fixed;
    }
  }
}
